<template>
  <div class="md-container" v-html="content"></div>
</template>

<script>
import MarkdownIt from 'markdown-it'

export default {
  props: {
    file: String,
  },
  data() {
    return {
      content: '',
    }
  },
  methods: {
    loadFile: async function() {
      try {
        const text = await fetch(this.file).then(response => response.text())
        const md = MarkdownIt()
        const result = md.render(text)
        this.content = result
      } catch (error) {
        console.error(error)
      }
    },
  },
  watch: {
    file() {
      this.loadFile()
    },
  },
  mounted() {
    this.loadFile()
  },
}
</script>
